import Vue from "vue";
import Vuex from "vuex";
import Buefy from "buefy";
import "es6-promise/auto";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faPlus,
  faCheck,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/vue";

import VueAutoscroll from "./VueAutoscroll";

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import "./assets/css/skin.scss";
import "armory-sdk/src/assets/css/main.css";

import baseStore from "armory-sdk/src/store";

import App from "./App.vue";


var faro = initializeFaro({
  url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/8826c53b9d6dae325e945808702d8ad8',
  app: {
    name: 'Alfred Test',
    version: '1.0.0',
    environment: 'production'
  },
  
  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),

    // Initialization of the tracing package.
    // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
    new TracingInstrumentation(),
  ],
});
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(faArrowLeft, faPlus, faCheck, faCheckCircle);
Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
});
Vue.directive('autoscroll', VueAutoscroll)

if (window.sentryDsn)
  Sentry.init({
    Vue,
    dsn: window.sentryDsn,
    tracesSampleRate: 1.0,
  });

const store = new Vuex.Store(baseStore);
new Vue({
  render: (h) => h(App),
  store,
}).$mount("#app");
